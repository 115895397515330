import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from '@azure/communication-common';
import {
  CallComposite,
  ChatComposite,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationCallAdapter,
  useAzureCommunicationChatAdapter,
  FluentThemeProvider,
  darkTheme
} from '@azure/communication-react';
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState 
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ChatClient } from '@azure/communication-chat';
import { initializeIcons } from '@fluentui/react';
import { useSearchParams } from 'react-router-dom';
import { Base64 } from "js-base64";
import { title } from 'process';
/**
 * Authentication information needed for your client application to use
 * Azure Communication Services.
 *
 * For this quickstart, you can obtain these from the Azure portal as described here:
 * https://docs.microsoft.com/en-us/azure/communication-services/quickstarts/identity/quick-create-identity
 *
 * In a real application, your backend service would provide these to the client
 * application after the user goes through your authentication flow.
 */

const ENDPOINT_URL = 'https://lucid-study.communication.azure.com/';//'<Azure Communication Services Resource Endpoint>';
let USER_ID = '<Azure Communication Services Identifier>';
let TOKEN =  '<Azure Communication Services Access Token>';
let roomId='';
let classTitle='';
/**
 * Display name for the local participant.
 * In a real application, this would be part of the user data that your
 * backend services provides to the client application after the user
 * goes through your authentication flow.
 */
 
let DISPLAY_NAME =  "unknown";
let DisplayNameOnly='';
let chatThreadId="";
const DecodeTokens=()=>{
  const [searchParams] = useSearchParams();
  const tokenized=searchParams.get("t")??"";
  const decodedString = Base64.decode(tokenized);
  const jsonTokens= JSON.parse(decodedString);
  DisplayNameOnly=jsonTokens.DisplayName??"";
  classTitle=jsonTokens.LearingSessionTitle??"";
  roomId =jsonTokens.RoomId??"unknown" ; 
  chatThreadId =jsonTokens.ChatThreadId??"unknown" ; 
  USER_ID =jsonTokens.UId??"unknown" ; 
  TOKEN = jsonTokens.Token??"unknown" ??"unknown";//'<Azure Communication Services Access Token>';
  DISPLAY_NAME= jsonTokens.DisplayName??"Student" +'('+jsonTokens.role+')';
  console.log('chat Id:'+roomId);

}
 
initializeIcons();
 
function App(): JSX.Element {
  // Arguments that would usually be provided by your backend service or
  // (indirectly) by the user.
  DecodeTokens();
  const { endpointUrl, userId, token, displayName, roomId, threadId } = useAzureCommunicationServiceArgs();

  // A well-formed token is required to initialize the chat and calling adapters.
  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(token);
    } catch {
      console.error('Failed to construct token credential');
      return undefined;
    }
  }, [token]);

  // Memoize arguments to `useAzureCommunicationCallAdapter` so that
  // a new adapter is only created when an argument changes.
  const callAdapterArgs = useMemo(
    () => ({
      userId: fromFlatCommunicationIdentifier(
        userId
      ) as CommunicationUserIdentifier,
      displayName,
      credential,
      locator: {
        roomId
      },
    }),
    [userId, credential, displayName, roomId]
  );
  const callAdapter = useAzureCommunicationCallAdapter(callAdapterArgs);

  // Memoize arguments to `useAzureCommunicationChatAdapter` so that
  // a new adapter is only created when an argument changes.
  const chatAdapterArgs = useMemo(
    () => ({
      endpoint: endpointUrl,
      userId: fromFlatCommunicationIdentifier(
        userId
      ) as CommunicationUserIdentifier,
      displayName,
      credential,
      threadId,
    }),
    [endpointUrl, userId, displayName, credential, threadId]
  );
  
  const chatAdapter = useAzureCommunicationChatAdapter(chatAdapterArgs);
 
  if (!!callAdapter && !!chatAdapter) {
    return (
      <FluentThemeProvider fluentTheme={darkTheme}>
        <div style={{ height: '100vh', display: 'flex' }}>
        <div style={containerStyle}>
          <CallComposite adapter={callAdapter}  />
        </div>
        <div style={containerChatStyle}>
          <ChatComposite adapter={chatAdapter}  />
        </div>
      </div>
      </FluentThemeProvider>
    );
  }
  if (credential === undefined) {
    return (
      <h3>Failed to connect. Your class may not be ready yet or has expired.</h3>
    );
  }
  return <h3>Connecting...</h3>;
}

const containerStyle: CSSProperties = {
  border: 'dotted 0.025rem black',
  margin: '0.1rem',
  width: '80vw',
};
const containerChatStyle: CSSProperties = {
  border: 'dotted 0.025rem black',
  margin: '0.1rem',
  width: '20vw',
};
/**
 * This hook returns all the arguments required to use the Azure Communication services
 * that would be provided by your backend service after user authentication
 * depending on the user-flow (e.g. which chat thread to use).
 */
function useAzureCommunicationServiceArgs(): {
  endpointUrl: string;
  userId: string;
  token: string;
  displayName: string;
  roomId: string;
  threadId: string;
} {
  const [threadId] = useState('');
  // For the quickstart, create a new thread with just the local participant in it.
  useEffect(() => {
    (async () => {
      document.title = classTitle;
    })();
  }, []);

  // For the quickstart, generate a random group ID.
  // The group Id must be a UUID.
//   let groupId = useRef(uuidv4());
// groupId.current=userThreadId;

  return {
    endpointUrl: ENDPOINT_URL,
    userId: USER_ID,
    token: TOKEN,
    displayName: DISPLAY_NAME,
    roomId: roomId,
    threadId:chatThreadId,
  };
}

export default App;